import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact Me" />
      <h1>Contact Me</h1>
      <ul>
        <li>Phone: 678-276-5461</li>
        <li>Email: derek.willingham@outlook.com</li>
        <li>
          Twitter:{" "}
          <a
            href="https://twitter.com/@Derek_Willing"
            rel="noopener noreferrer"
            target="_blank"
          >
            @Derek_Willing
          </a>
        </li>
        <li>
          GitLab:{" "}
          <a
            href="https://gitlab.com/DirtySoc"
            rel="noopener noreferrer"
            target="_blank"
          >
            @DirtySoc
          </a>
        </li>
        <li>
          GitHub:{" "}
          <a
            href="https://github.com/DirtySoc"
            rel="noopener noreferrer"
            target="_blank"
          >
            @DirtySoc
          </a>
        </li>
      </ul>
      <p>This page will eventually include a contact me form.</p>
    </Layout>
  )
}

export default ContactPage
